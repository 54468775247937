import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Select,
  Space,
  Spin,
  Tooltip,
} from "antd";
import React from "react";
import { MdInfo } from "react-icons/md";
import { PiCaretDownLight } from "react-icons/pi";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  fetchRefineFilterData,
  resetRefinementFilterValues,
  updateRefinementFilterValues,
} from "../../redux/reducer/compareTableSlice";

function Filters({ disabled = false }) {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();
  const [searchParams] = useSearchParams();
  
  const {
    refinementFilterOptions,
    initialRefinementFilterValues,
    isCompareTableLoading,
  } = useSelector((state) => state.compareTable);

  const {
    skuFilterOptions,
  } = useSelector((state) => state.search);

  refinementFilterOptions?.psr?.map((option) => {
    const key = Object.keys(option)[0];
    const lable = Object.values(option)[0];
    
  })

  const handleReset = () => {
    filterForm.resetFields();
    dispatch(resetRefinementFilterValues());
  };

  const handleApply = (values) => {
    
    const allParams = {
      dataCode: searchParams.get("product"),
      corporateBrandCode: searchParams.get("brand") || null,
      skuDescription: searchParams.get("productDesc"),
      type: "landingPage",
      filterDropdownData: `'${searchParams.get("division")}'` || "null",
      distributionCenter: searchParams.get("distributionCenter") || null,
    };

  let divisionLable="";
  let CategoryLable="";
  let productLable;
  skuFilterOptions?.Product?.map((option) => {
    productLable = Object.keys(option)[0];
  })
  skuFilterOptions?.Division?.map((option) => {
    const lable = Object.values(option)[0];
    divisionLable = lable.toString().split('-')[0].trim()
  })
  skuFilterOptions?.Category?.map((option) => {
    const lable = Object.values(option)[0];
    CategoryLable = lable.toString().split('-')[0].trim()
  })

    const payload = {
      targetSKU: allParams?.dataCode,
      dimensionRange: Number(values.psr),
      matchPercentageRange: Number(values.minMatch),
      capacityRange: values.maxCCU,
      costRange: null,
      distributionCenter:  searchParams.get("distributionCenter") === "null"
      ? null
      : searchParams.get("distributionCenter"),
      
      rmCommMatchSort: values?.mh === 'commodity' ? (values?.sortBy === 'RM Descending' ? 'descending' : (values?.sortBy === 'RM Ascending' ? 'ascending' : null)) : null,
      pmCommMatchSort: values?.mh === 'commodity' ? (values?.sortBy === 'PM Descending' ? 'descending' : (values?.sortBy === 'PM Ascending' ? 'ascending' : null)) : null,
      rmCompMatchSort: null,
      pmCompMatchSort: null,
      plmRMMatchSort: values?.mh === 'plm' ? (values?.sortBy === 'RM Descending' ? 'descending' : (values?.sortBy === 'RM Ascending' ? 'ascending' : null)) : null,
      plmPMMatchSort: values?.mh === 'plm' ? (values?.sortBy === 'PM Descending' ? 'descending' : (values?.sortBy === 'PM Ascending' ? 'ascending' : null)) : null,
      capacityAscending:values?.sortBy === 'Capacity Ascending' ? 'ascending' : null,
      capacityDescending:values?.sortBy === 'Capacity Descending' ? 'descending' : null,
      costAscending:values?.sortBy === 'Cost Ascending' ? 'ascending' : null,
      costDescending:values?.sortBy === 'Cost Descending' ? 'descending' : null,
      match: values.mh,
      date: values.utf,
      params: {
        TargetSKU: productLable,
        CategoryName: CategoryLable,
        SubSectorName: skuFilterOptions?.Subsector_Selected?.map((row) => Object.values(row)[0].toString().split('-')[0].trim()),
        FormName: skuFilterOptions?.Form_Selected?.map((row) => Object.values(row)[0].toString().split('-')[0].trim()),
        DivisionName: divisionLable,
        
        PercCountPC: values.mh == "commodity" ? Number(values.minMatch): 0,
        PercCountPLM: values.mh == "plm" ? Number(values.minMatch): 0,
        Pack_Size: values.psr
      }
    };

    dispatch(fetchRefineFilterData(payload));
    dispatch(updateRefinementFilterValues(values));
  };

  return (
    <Card bordered>
      <Spin spinning={isCompareTableLoading}>
        <Form
          name="refinement_filters"
          form={filterForm}
          layout="vertical"
          size="small"
          autoComplete="off"
          disabled={disabled}
          initialValues={initialRefinementFilterValues}
          onFinish={handleApply}>
          <Row gutter={[8, 8]} align="bottom">
            <Col span={24}>
              <Form.Item name={`psr`} 
              label={
                // `Pack Size Match %`
                <span>
                    Pack Size Match %
                    <Tooltip title="Select the +/- Match % level for the Pack Size that you wish to determine matches on">
                      <MdInfo
                        size={12}
                        style={{
                          marginLeft: 8,
                          marginBottom: -3,
                          color: "#7D7D7D",
                        }}
                      />
                    </Tooltip>
                  </span>
                }>
                <Select
                  placeholder="Select"
                  options={refinementFilterOptions.psr}
                  suffixIcon={<PiCaretDownLight size={12} color="#292929" />}
                />
              </Form.Item>
              <Form.Item name={`minMatch`} 
              label={
                // `Minimum Match %`
                <span>
                    Minimum Match %
                    <Tooltip title="Select the mimimum Match % you want to apply across all metrics">
                      <MdInfo
                        size={12}
                        style={{
                          marginLeft: 8,
                          marginBottom: -3,
                          color: "#7D7D7D",
                        }}
                      />
                    </Tooltip>
                  </span>
                }>
                <Select
                  placeholder="Select"
                  options={refinementFilterOptions.minMatch}
                  suffixIcon={<PiCaretDownLight size={12} color="#292929" />}
                />
              </Form.Item>
              <Form.Item
                name={`maxCCU`}
                label={
                  // `Maximum Category Utilization`
                  <span>
                    Maximum Category Utilization
                    <Tooltip title="Select the maximum Category Utilization %  you want to apply for all SU">
                      <MdInfo
                        size={12}
                        style={{
                          marginLeft: 8,
                          marginBottom: -3,
                          color: "#7D7D7D",
                        }}
                      />
                    </Tooltip>
                  </span>
                  }>
                <Select
                  placeholder="Select"
                  options={refinementFilterOptions.maxCCU}
                  suffixIcon={<PiCaretDownLight size={12} color="#292929" />}
                />
              </Form.Item>
              <Form.Item name={`utf`} 
              label={
                // `Utilization Time Frame`
                <span>
                    Utilization Time Frame
                    <Tooltip title="Select the time frame to be considered for calculating SU Capacity Utilization">
                      <MdInfo
                        size={12}
                        style={{
                          marginLeft: 8,
                          marginBottom: -3,
                          color: "#7D7D7D",
                        }}
                      />
                    </Tooltip>
                  </span>
                }>
                <Select
                  className="ant-material-select"
                  placeholder="All"
                  mode="multiple"
                  showSearch={false}
                  maxTagCount={0}
                  maxTagPlaceholder={(values) => (
                    <>
                      {values.length === refinementFilterOptions.utf.length
                        ? "All"
                        : values.length === 1
                        ? `1 item selected`
                        : `${values.length} items selected`}
                    </>
                  )}
                  options={refinementFilterOptions.utf}
                  suffixIcon={<PiCaretDownLight size={12} color="#292929" />}
                />
              </Form.Item>
              <Form.Item
                name={`mh`}
                label={
                  <span>
                    Material Hierarchy
                    <Tooltip title="Please select at what level of the material hierarchy you wish to determine matches on">
                      <MdInfo
                        size={12}
                        style={{
                          marginLeft: 8,
                          marginBottom: -3,
                          color: "#7D7D7D",
                        }}
                      />
                    </Tooltip>
                  </span>
                }>
                <Select
                  placeholder="Select"
                  options={refinementFilterOptions.mh}
                  suffixIcon={<PiCaretDownLight size={12} color="#292929" />}
                />
              </Form.Item>
              <Form.Item name={`sortBy`} 
              label={
                // `Sort By - Match %`
                <span>
                    Sort By - Match %
                    <Tooltip title="Select the sort order for the comparison list">
                      <MdInfo
                        size={12}
                        style={{
                          marginLeft: 8,
                          marginBottom: -3,
                          color: "#7D7D7D",
                        }}
                      />
                    </Tooltip>
                  </span>
                }>
                <Select
                  placeholder="Select"
                  options={refinementFilterOptions.sortBy}
                  suffixIcon={<PiCaretDownLight size={12} color="#292929" />}
                />
              </Form.Item>

              <Space
                hidden={disabled}
                direction="vertical"
                style={{ width: "100%", marginTop: 64 }}>
                <Button block onClick={handleReset}>
                  Reset
                </Button>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  style={{ backgroundColor: "#424242" }}>
                  Apply Filters
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>
      </Spin>
    </Card>
  );
}

export default Filters;
